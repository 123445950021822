import { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArrowBottom from "../../assets/icons/ArrowBottom";
import withoutAuthProvider from "../../services/withoutAuthProvider";

export const DropdownLot = ({ title_slot, auction_id, styleDropdown }) => {
  const { push } = useHistory();
  const [dataUnit, setDataUnit] = useState([]);
  const { id } = useParams();
  const [reachedEnd, setReachedEnd] = useState(false);
  const dropdownMenuRef = useRef(null);
  const [page, setPage] = useState(1);
  const toastError = (message) => {
    return toast.error(message, {
      id: "error-message",
      duration: 3000,
    });
  };

  const getLot = async (pageNumber) => {
    try {
      const params = {
        page: pageNumber,
        per_page: 10,
        auction_id: id,
        exclusive_seller_slug: process.env.REACT_APP_WEB_TYPE === 'exclusive' ? 'cnaf' : null
      };
  
      const response = await withoutAuthProvider.getDataWithoutAuth(
        "/api/unit",
        params
      );
  
      if (response?.status === 200) {
        const { data } = response?.data?.data;
  
        // Filter data based on is_cancel_auction === '0'
        const filteredData = data.filter(unit => unit.is_cancel_auction === '0');
  
        if (filteredData.length === 0) {
          setReachedEnd(true);
        } else {
          setDataUnit((prevData) => [...prevData, ...filteredData]);
        }
      } else {
        toastError(response?.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toastError("Failed to fetch data");
    }
  };
  

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = dropdownMenuRef.current;
    const lastScroll = clientHeight + scrollTop + 1;

    if (lastScroll >= scrollHeight && !reachedEnd) {
      const nextPage = page + 1; 
      setPage(nextPage);
    }
  };

  useEffect(() => {
    getLot(page);
  }, [id, page]);
  return (
    <Dropdown align="end">
      <Dropdown.Toggle
        id="dropdown-basic"
        variant="light"
        className="disable-caret  cursor-pointer bg-header"
      >
        <div className="px-3 py-2 d-flex align-items-center">
          {title_slot}
          <ArrowBottom />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{ ...styleDropdown, maxHeight: dataUnit?.length <= 5 ? "150px" : dataUnit?.length <= 7 ? "185px" : "300px" }}
        onScroll={handleScroll}
        ref={dropdownMenuRef}
      >
        {dataUnit &&
          dataUnit.map((unit, index) => {
            return (
              <Dropdown.ItemText key={index}>
                <div
                  className="dropdown-item-header fw-normal"
                  onClick={() => {
                    push(
                      "/time-bid-auction/" + `${unit?.auction?.id}/` + unit?.id
                    );
                    window.location.reload();
                  }}
                >
                  Lot{" "}
                  {unit?.auction?.auction_lane_name && unit?.lot_number
                    ? unit?.auction?.auction_lane_name + unit?.lot_number
                    : "-"}
                </div>
              </Dropdown.ItemText>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
